.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

h3 {
  color: white;
}

/* TC Styling */

div.css-919eu4 {
   background-color: rgba(0, 0, 0, 0);
}

div.css-1tx0k9e {
  position: relative;
}

html body {
  overflow: visible !important;
}

/*Aeroport Font Family - Borrowed from Shopify */
@font-face {
    font-family: 'Aeroport';
    src: url(//cdn.shopify.com/s/files/1/0255/7725/9086/t/36/assets/Aeroport-bold.eot?v=4501669306956787989);
    src:
        url(//cdn.shopify.com/s/files/1/0255/7725/9086/t/36/assets/Aeroport-bold.eot?%23iefix&v=4501669306956787989) format('embedded-opentype'),
        url(//cdn.shopify.com/s/files/1/0255/7725/9086/t/36/assets/Aeroport-bold.woff2?v=8430379176844585169) format('woff2'),
        url(//cdn.shopify.com/s/files/1/0255/7725/9086/t/36/assets/Aeroport-bold.woff?v=16367529782070192572) format('woff'),
        url(//cdn.shopify.com/s/files/1/0255/7725/9086/t/36/assets/Aeroport-bold.ttf?v=13815612108311626995) format('truetype'),
        url(//cdn.shopify.com/s/files/1/0255/7725/9086/t/36/assets/Aeroport-bold.svg%23Aeroport-Bold?60537) format('svg');
    font-style: normal;
    font-weight: 700;
  }
  @font-face {
    font-family: 'Aeroport';
    src: url(//cdn.shopify.com/s/files/1/0255/7725/9086/t/36/assets/Aeroport-light.eot?v=741492022319172014);
    src:
        url(//cdn.shopify.com/s/files/1/0255/7725/9086/t/36/assets/Aeroport-light.eot?%23iefix&v=741492022319172014) format('embedded-opentype'),
        url(//cdn.shopify.com/s/files/1/0255/7725/9086/t/36/assets/Aeroport-light.woff2?v=16549788850746659353) format('woff2'),
        url(//cdn.shopify.com/s/files/1/0255/7725/9086/t/36/assets/Aeroport-light.woff?v=14499325069445092940) format('woff'),
        url(//cdn.shopify.com/s/files/1/0255/7725/9086/t/36/assets/Aeroport-light.ttf?v=8417430077711128339) format('truetype'),
        url(//cdn.shopify.com/s/files/1/0255/7725/9086/t/36/assets/Aeroport-light.svg%23Aeroport-Light?60537) format('svg');
    font-style: normal;
    font-weight: 300;
  }
  @font-face {
    font-family: 'Aeroport';
    src: url(//cdn.shopify.com/s/files/1/0255/7725/9086/t/36/assets/Aeroport-light-italic.eot?v=4852377472607028657) format('embedded-opentype'),
        url(//cdn.shopify.com/s/files/1/0255/7725/9086/t/36/assets/Aeroport-light-italic.woff2?v=10251142074552535989) format('woff2'),
        url(//cdn.shopify.com/s/files/1/0255/7725/9086/t/36/assets/Aeroport-light-italic.woff?v=7947351889260190597) format('woff'),
        url(//cdn.shopify.com/s/files/1/0255/7725/9086/t/36/assets/Aeroport-light-italic.ttf?v=16513230624831051278) format('truetype'),
        url(//cdn.shopify.com/s/files/1/0255/7725/9086/t/36/assets/Aeroport-light-italic.svg%23Aeroport-LightItalic?60537) format('svg');
    font-style: italic;
    font-weight: 300;
  }
/* Sons Styling */
*, *:after, *:before { -webkit-box-sizing: border-box; -moz-box-sizing: border-box; box-sizing: border-box; }
body, html { font-size: 100%; padding: 0; margin: 0 !important; }
body {font-family: 'Aeroport';  background-color: #eefad5 !important;}
p, .p, .p :not(br), body {font-family: 'Aeroport' !important;font-weight: 100 !important;font-size: 18px !important;letter-spacing: -0.02em !important;line-height: 22px !important;padding: 0 !important;}
h1:first-letter{text-transform: capitalize !important;}
h1 {font-family: 'Aeroport' !important; text-transform: lowercase; font-weight: 600 !important; font-size: 36px !important;letter-spacing: -0.04em !important;line-height: 38px !important;padding: 0 !important;}
h2, h3:first-letter{text-transform: capitalize !important;}
h2, h3 {font-family: 'Aeroport' !important; text-transform: lowercase; font-weight: 600 !important;font-size: 24px !important;letter-spacing: -0.03em !important;line-height: 26px !important;padding: 0 !important;}
.bottom-h1 {font-family: 'Aeroport' !important; text-transform: lowercase; font-weight: 600 !important; font-size: 36px !important;letter-spacing: -0.04em !important;line-height: 38px !important;padding: 0 !important; margin-top: 15px;}
/* Elements */
.sons-contents {background: #fff !important; padding: 10px !important; border-radius: 2px; color: #24272A !important;}
.sons-contents .text {color: #24272A !important;}
.sons-contents p, h1, h2, h3 {color: #24272A !important;}
.sons-contents img {height: auto;max-width: 100%;padding-top: 35px;width: 360px;margin: 0 auto;display: block;}
.dlexample::before {content: 'Driving License Example'; margin-bottom: 10px; font-size: 12px; color: #ccc; font-family: 'Aeroport'; }
.dlexample { margin-bottom: 15px !important; }
.verification button {/* height: 30px; */margin-left: auto;margin-right: auto;width: 100%;}
.explainer {margin: 20px;}
.form-contents p {color: #24272A !important; padding-bottom: 20px;}
.text {color: #24272A !important; font-family: 'Aeroport' !important; font-size: 18px;}
label {width: 100%; color: #24272A !important; font-family: 'Aeroport'!important; font-size: 18px;}
input {width: 100%; color: #24272A !important; padding: 15px 10px; border: #ccc 1px solid; margin: 10px 0; font-size: 16px !important;font-weight: 300;-webkit-border-radius: 3px; border-radius: 3px;}
label[for="passport"] input {width: 100% !important; margin-left: 0px !important;}
.sons-logo-container {padding: 20px 10px; background: #24272A; border-radius: 2px;  }
.sons-logo-container img {max-width: 100%; height: auto; width: 130px;}
.sons-logo { margin-bottom: 0px !important;}
.verification img {max-width: 100%; height: auto; }
.css-1tdiai5 {
    background: none !important;
}
@media screen and (min-width: 0px) and (max-width: 450px) {
    .css-1tdiai5 {
        margin: 10px !important;
    }
    .react-tooltip {
        width: 98%;
        position: fixed;
    }
    .verification img {
        width: 100%;
    }
}
.failure-box {text-align: center; background: #fff; padding: 30px;}
.email-text, .failure-box, .success-box {color: #24272A !important;}
form {margin: 0 5px; padding-top:20px;}
.button-container {position: relative !important; z-index: 1 !important;}
.loader-wrapper {z-index: 999 !important; display: block; position: relative;}
/* Buttons, Btn's */
.button, .btn, button:first-letter{text-transform: capitalize !important;}
.button, .btn, button {background-color: #24272a;border-radius: 0;border-width: 0;color: #f0fad7;display: block;font-weight: 600;overflow: hidden;padding: 1.25rem;position: relative;
text-align: center;width: 100%; font-family: 'Aeroport';font-size: 18px;text-transform: lowercase; margin: 0px 5px;}
.button span, .btn span, button span { position: relative; z-index: 5;}
.button small, .button .small, .btn small, .btn .small, button small, button .small {display: block;font-size: 12px !important;}
.button:hover, .btn:hover, button:hover {background-color: #24272a;}
.button:hover span, .btn:hover span, button:hover span {color: #f0fad7;}
.button::after, .btn::after, button::after {content: "";left: 0;display: block;background: #4100e6;top: 0;position: absolute;bottom: 0;right: 100%;z-index: 1;
transition: transform 0.5s, width 0.5s, height 0.5s, top 0.5s, right 0.5s, left 0.5s, bottom 0.5s, padding 0.5s, margin 0.5s, background-color 0.5s, color 0.5s; transition-timing-function: ease-in-out;}
.button:hover::after, .btn:hover::after, button:hover::after {position: absolute;right: 0;z-index: 1;transition: transform 0.5s, width 0.5s, height 0.5s, top 0.5s, right 0.5s, left 0.5s, bottom 0.5s, padding 0.5s, margin 0.5s, background-color 0.5s, color 0.5s;transition-timing-function: ease-in-out;}
.dlexplain { padding-left: 20px !important;padding-right: 20px !important; display: block; margin-bottom: -20px; }
.sons-arrow { background-color: rgb(240, 250, 215) !important; width: 10px; height: 10px; }
.sons-tooltip .overlay { position: absolute; height: 7px; width: 91%; background: #24272A; z-index: 9999; bottom: 0; }
.non-uk-helper svg, .driving-license-helper svg, .middle-name-helper svg { position: relative; top: 3px; left: 5px; }
.question-inner {background: #f0fad7; box-shadow: inset 0px 0px 0px 0px rgb(36 39 42); border-radius: 20px;}
.sons-content-wrapper {overflow: hidden;}
.sons-content-wrapper .MuiPaper-root {overflow: hidden;}
.verification {overflow: hidden;}