.centre {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30%;
    margin-left: auto;
    margin-right: auto;
    flex-direction: column;
}
body {
    background: black;
}
.container {
    display: flex;
    width: 70%;
    flex-direction: column;
    position: relative;
}
.validate {
    color: red;
    font-weight:bold;
    display: inline !important;
}
.verification label {
    margin-bottom: 10px;
}
.text,
label {
    font-family: "Roboto-Regular", sans-serif;
    color: white;
}
h1 {
    font-family: "Roboto-Regular", sans-serif;
    color: white;
    font-weight: bold;
    text-align: center;
    font-size: 50px;
}
@font-face {
    font-family: 'Roboto-Regular';
    src:  url('../fonts/Roboto-Regular.ttf') format('ttf')
}
label[for="customer_id"] {
    display: none;
}
.loader-wrapper {
    position: absolute;
    background: rgba(238, 250, 213, 0.85);
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
}
.loader-wrapper svg {
    display: block;
    margin: auto;
}
.loader-wrapper .loader-wrapper-text {
    margin: auto;
}
.loader-wrapper .loader-wrapper-text:after {
    content: 'Validating your Identity';
    display: block;
    color: black;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 16px;
    margin-top: -20px;
}
.failure-box,
.success-box,
.email-text {
    color: white;
}

.email-text {
    text-align: center;
}

.failure-box {
    text-align: center;
}

.bottom-h1 {
    margin-top: -35px;
    font-size: 24px;
}

.h3-bottom {
    text-align: center;
    margin-top: -11px;
    font-size: 14px;
}

.explainer {
    margin-top: 25px;
}

.form-contents {
    display: flex;
    flex-direction: column;
}

.form-contents button {
    min-width: 150px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    height: 40px;
}

.button-container {
    display: flex;
    flex-basis: fit-content;
}

.submit-button {
    margin-top: 26px;
}

.sons-logo {
    position: relative;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 54px;
}

label[for="countrySelector"] span {
    margin-bottom: 10px;
    display: block;
}

#countrySelector > div {
    min-height: 50px;
}

.success-box {
    overflow: hidden;
}

.Toastify__toast-container {
    font-size: 14px;
}

.Toastify__close-button {
    max-width: 15px;
}